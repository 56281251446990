::selection{
    background-color: teal;
    color: white;
    border-radius: 10px;
}

body{
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f0f0f0;
}

.container{
    display: grid;
    grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 10px;

}
.footer{
    text-align: center;
    padding: 10px;
    background-color: teal;
    color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
}
#title{
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    margin: 0;
    color: teal;
}

#cover{
    width: 100%;
    height: 100%;
    background-image: url('https://source.unsplash.com/1920x1080/?nature,water');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
}

#cover h1{
    font-size: 3em;
    color: rgb(249, 187, 187);
    text-align: center;
    margin-top: 30%;
}

#cover p{
    font-size: 1.5em;
    color: white;
    text-align: center;
}

#cover button{
    font-size: 1.5em;
    color: white;
    background-color: teal;
    border: none;
    padding: 10px 20px;
    margin-top: 20px;
    cursor: pointer;
}

#cover button:hover{
    background-color: #333;
}

#cover button:active{
    background-color: #555;
}

#cover button:focus{
    outline: none;
}

#cover button:active{
    transform: translateY(2px);
}

#textArea{
    width: 90%;
    height: 30px;
    padding: 20px;
    font-size: 1.5em;
    color: teal;
    text-align: center;
    margin: 0 auto;
    display: block;
    background: #f0f0f0;
    border: 1px solid teal;
    border-radius: 50px;
    margin-bottom: 2px;
}